import React from "react";
import styles from './download.module.css';
import './download.css';

export default () => {
  return <div className={styles.container}>
    <div className={styles.left}>
      <div className={styles.codeBoxContainer}>
        <div className="codeBox">
          <img src="http://s.lianmeihu.com/x-uploader/image/2023-06-08/c967d8db1b26f4cd9410f94a5439d5a4" />
          <p>iOS</p>
        </div>
        <div className="codeBox">
          <img src="http://s.lianmeihu.com/x-uploader/image/2023-06-08/4b7adf1d3ce20d7a2c1205b30e76b63d" />
          <p>Android</p>
        </div>
        <div className="codeBox">
          <img src="http://s.lianmeihu.com/x-uploader/image/2023-06-08/f626fa46f1b1ac66aa51794a04be0908" />
          <p>小程序</p>
        </div>
        <div className="codeBox">
          <img src="http://s.lianmeihu.com/x-uploader/image/2023-06-09/a0caa1fdec4a2dcaee0eb318e98d5b37" />
          <p>公众号</p>
        </div>
      </div>
    </div>
    <div className={styles.right}>
      <img style={{width:500}} src="http://s.lianmeihu.com/x-uploader/image/2023-06-08/4f9b6c0cb225a00339aa32f5875e9acb" />
      <img style={{width:500, borderRadius: 10}} src="http://s.lianmeihu.com/x-uploader/image/2023-06-08/883df617dcf36eb2ac394b296af0fcce" />
    </div>
  </div>
}